// Modules
import { NgModule } from '@angular/core';

// Providers
import { HttpClientInterceptor } from './httpClient.interceptor';
import {  HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserProvider } from './user.providers';
import { SuscriptionProvider } from './suscription.providers';
import { ClientProvider } from './client.providers';
import { RondasProvider } from './rondas.providers';
import { MonitoringProvider } from './monitoring.providers';
import { ReportsProvider } from './reports.providers';
import { TrackingProvider } from './tracking.providers';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    UserProvider,
    SuscriptionProvider,
    ClientProvider,
    RondasProvider,
    MonitoringProvider,
    ReportsProvider,
    TrackingProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    }
  ],
})
export class AppModuleProviders { }
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
    { path: 'recovery', loadChildren: () => import('./pages/recovery/recovery.module').then(m => m.RecoveryModule) },
    { path: 'forgot', loadChildren: () => import('./pages/forgot/forgot.module').then(m => m.ForgotModule) },
    { path: 'suscription', loadChildren: () => import('./pages/suscription/suscription.module').then(m => m.SuscriptionModule) },
    { path: 'users', loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule) },
    { path: 'clients', loadChildren: () => import('./pages/client/client.module').then(m => m.ClientModule) },
    { path: 'rondas', loadChildren: () => import('./pages/rondas/rondas.module').then(m => m.RondasModule) },
    { path: 'splash', loadChildren: () => import('./pages/splash/splash.module').then(m => m.SplashModule) },
    { path: 'monitoring', loadChildren: () => import('./pages/monitoring/monitoring.module').then(m => m.MonitoringModule) },
    { path: 'reports', loadChildren: () => import('./pages/report/report.module').then(m => m.ReportModule) },
    { path: '', loadChildren: () => import('./pages/splash/splash.module').then(m => m.SplashModule) },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
  })
export class AppRoutingModule { }
import { Component } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { AppService, INotification } from 'app/services/app.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.css',
  animations: [ 
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ transform: 'translateY(0.5rem)', opacity: 0 }),
        animate('300ms ease-out', style({  transform: 'translateY(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('300ms ease-in', style({ transform: 'translateY(0.5rem)', opacity: 0 }))
      ])
    ])
  ]
})
export class NotificationComponent {
  isNotification = false;
  message: string | undefined = '';
  type: string | undefined = '';


  constructor(private appService: AppService) {
    this.appService.notificationIn$.subscribe({
      next: (data: INotification) => {
        this.isNotification = data.state;
        this.message = data.message
        this.type = data.type

        setTimeout(() => {
          this.close();
        }, 5000);
      }
    })
  }
  
  close(){
    this.isNotification = false
  }
  
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './app-base.component';
import { AppRoutingModule } from '../../app.routes';
import { NotificationModule } from '../notification/notification.module';
import { PipesModule } from 'app/pipes/pipes.module';

@NgModule({
  declarations: [MenuComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    NotificationModule,
    PipesModule
  ],
  exports: [MenuComponent]
})
export class MenuModule { }
import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  splash: boolean = true;

  constructor(private router: Router) {
    this.router.navigate(['/splash'], { queryParams: { path: location.pathname } });
  }
  
}

// Modules
import { NgModule } from '@angular/core';
import { AuthService } from './auth.service';
import { AppService } from './app.service';

@NgModule({
  imports: [],
  providers: [
    AuthService,
    AppService,
  ],
})
export class AppModuleServices { }
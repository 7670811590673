
<app-notification></app-notification>
<div *ngIf="isLoggedIn">
  <div *ngIf='isOffCanvasMenuDialog' class="relative z-50 lg:hidden" role="dialog" aria-modal="true">
    
    <div @opacity *ngIf='isOffCanvasMenu' class="fixed inset-0 bg-gray-900/80"></div>

    <div @translateX *ngIf='isOffCanvasMenu'  class="fixed inset-0 flex">
      <div *ngIf='isOffCanvasMenu' (click)="toggleOffCanvasMenu()" class="fixed inset-0"></div>
      <div @translateX *ngIf='isOffCanvasMenu' class="relative mr-16 flex w-full max-w-xs flex-1">
        <div @opacityInOut *ngIf='isOffCanvasMenu' class="absolute left-full top-0 flex w-16 justify-center pt-5">
          <button type="button" (click)="toggleOffCanvasMenu()" class="-m-2.5 p-2.5">
            <span class="sr-only">Close sidebar</span>
            <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
          <div class="flex h-16 shrink-0 items-center logo">
            <img class="h-8 w-auto" src="assets/logo-blank.svg?color=indigo&shade=500" alt="Check 360">
          </div>
          <nav class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" class="-mx-2 space-y-1">
                  <li *ngFor="let item of menu">
                    <a [routerLink]="[item.url]" routerLinkActive #rla="routerLinkActive" class="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold" [ngClass]="{'bg-gray-800 text-white': rla.isActive, 'text-gray-400 hover:text-white hover:bg-gray-800': !rla.isActive}">
                      <div [innerHTML]="getModuleIcon(item.module) | safeHtml"></div>
                      {{item.module}}
                    </a>
                  </li>
                </ul>
              </li>
              <li class="mt-auto">
                <a (click)="logout()" class="cursor-pointer group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
                  </svg>
                  
                  Salir
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col">
    <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
      <div class="flex h-16 shrink-0 items-center logo">
        <img class="h-8 w-auto" src="assets/logo-blank.svg?color=indigo&shade=500" alt="Check 360">
      </div>
      <nav class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="-mx-2 space-y-1">
              <li *ngFor="let item of menu">
                <a  [routerLink]="[item.url]" routerLinkActive #rla="routerLinkActive" class="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold" [ngClass]="{'bg-gray-800 text-white': rla.isActive, 'text-gray-400 hover:text-white hover:bg-gray-800': !rla.isActive}">
                  <div [innerHTML]="getModuleIcon(item.module) | safeHtml"></div>
                  {{item.module}}
                </a>
              </li>
            </ul>
          </li>
          <li class="mt-auto">
            <a (click)="logout()" class="cursor-pointer group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
              </svg>              
              
              Salir
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <div class="flex flex-1 flex-col lg:pl-64">
    <div class="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden">
      <button type="button" (click)="toggleOffCanvasMenu()" class="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
        <span class="sr-only">Abrir sidebar</span>
        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </button>
    </div>
    <main class="py-10">
      <div class="px-4 sm:px-6 lg:px-8">
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>

  
</div>

<div *ngIf="!isLoggedIn">
  <router-outlet></router-outlet>
</div>

import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { finalize, tap, from, switchMap } from "rxjs";
import { AuthService } from "app/services/auth.service";
import { AppService } from "app/services/app.service";
import { Router } from "@angular/router";

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  private loading: any;
  constructor( private authService: AuthService, private appService: AppService, private router: Router, ) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler) {
    if (httpRequest.responseType === 'json') {
      return this.handleJsonResponse(httpRequest, next, this.authService.getAuthToken());
    } else {
      return next.handle(httpRequest);
    }
  }

  private handleJsonResponse(httpRequest: HttpRequest<any>, next: HttpHandler, token: string) {
    let headers = {
			'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`
		};

    httpRequest = httpRequest.clone({headers: new HttpHeaders(headers)});
    return next.handle(httpRequest).pipe(
      tap(
        {
          error: (err)=> {
            if(err.status === 401) {
              this.authService.logout();
              this.appService.cleanViewSuscription();
              this.router.navigate(['/login']);
              this.hideLoading();
              throw(err.error)
            }

            if(err.status === 403) {
              this.showToastError("No tienes acceso a esta operación");
              this.hideLoading();
              throw(err.error)
            }

            let message = '';
            try{
              const messages = JSON.parse(err.error.data.message)
              for (const property in messages) {
                message = `- ${messages[property]}`;
              }
            } catch(e) {
              message = "Ha ocurrido un error. Vuelva a intentarlo";
            }

            this.showToastError(message);
            this.hideLoading();

            throw(err.error)
          },
          next: (data: any) => {
            if(data.body && data.body.message) {
              this.showToastSuccess(data.body.message);
            }
          },
        }
      ),
      finalize( () => {
        this.hideLoading();
      })
    );
  }

  private showToastSuccess(message: string) {
    this.appService.setNotification({
      state: true,
      message,
      type: 'success'
    });
  }  

  private showToastError(message: string) {
    this.appService.setNotification({
      state: true,
      message,
      type: 'error'
    });
  }

  private async showLoading() {
    // if(!this.appModel.notLoading) {
      // this.loading = await this.loadingCtrl.create({
      //   message: 'Cargando ...'
      // });
  
      // await this.loading.present();
    // }    
  }

  private async hideLoading() {
    if(this.loading) {
      // await this.loading.dismiss(); 
    }

    // this.appModel.notLoading = false;
  }
}